<template>
  <v-dialog v-model="show" max-width="550" persistent>
    <v-card class="pa-0">
      <v-card-title class="titleStyle">
        <div class="pa-4"> Histórico de atualização</div>
        <div class="pa-4">
          <v-btn id="btnCloseX" icon dark @click="show = false">
            <v-icon class="ma-0">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <div
        :style="'height: 420px !important; max-height: 300px !important;background-color: #e7e7fa'"
        id="cardScroll2"
        class="px-4"
      >
        <v-card-text v-for="h in history" v-bind:key="h.id" class="h-title pt-4 pb-0 mt-4">
          <div class="row h-row mb-0 rounded">
            <div class="col-7 pa-4">
              <v-subheader class="pa-0" style="max-height: 24px">Atualização realizada por </v-subheader>
              <v-subheader class="pa-0" style="max-height: 24px"
                ><strong>{{ h.user }}</strong></v-subheader
              >
            </div>
            <div class="col-5 pa-4">
              <v-subheader class="mt-0 pa-0 h-line d-flex justify-end" style="max-height: 24px">Data/hora</v-subheader>
              <v-subheader class="pa-0 d-flex justify-end" style="max-height: 24px"><strong>{{ h.date }}</strong></v-subheader>
            </div> 
            <div class="col-12 px-4 pt-0 pb-4" v-if="h.differences.before.name !== h.differences.after.name">
              <v-divider class="pb-4" ></v-divider>
              <v-subheader class="pa-0" style="max-height: 24px"><strong> Informação alterada</strong></v-subheader>
              <v-subheader class="pa-0" style="max-height: 24px" >Nome</v-subheader > 
              <v-subheader class="pa-0" style="max-height: 24px" >
                De &nbsp<strong>{{start_and_end( h.differences.before.name, 20)}} </strong>&nbsp para &nbsp<strong>{{start_and_end( h.differences.after.name, 20)}}</strong>
              </v-subheader> 
            </div> 
            <div class="col-12 px-4 pt-0 pb-4" v-if="h.differences.before.desc !== h.differences.after.desc">
              <v-divider class="pb-4" ></v-divider>
              <v-subheader class="pa-0" style="max-height: 24px"><strong> Informação alterada</strong></v-subheader>
              <v-subheader class="pa-0" style="max-height: 24px" >Descrição</v-subheader > 
              <v-subheader class="pa-0" style="max-height: 24px" >
                De &nbsp<strong>{{start_and_end( h.differences.before.desc, 20)}} </strong>&nbsp para &nbsp<strong>{{start_and_end( h.differences.after.desc, 20)}}</strong>
              </v-subheader> 
            </div> 
            <div class="col-12 px-4 pt-0 pb-4" v-if="h.differences.before.start !== h.differences.after.start">
              <v-divider class="pb-4" ></v-divider>
              <v-subheader class="pa-0" style="max-height: 24px"><strong> Informação alterada</strong></v-subheader>
              <v-subheader class="pa-0" style="max-height: 24px" >Início</v-subheader > 
              <v-subheader class="pa-0" style="max-height: 24px" >
                De &nbsp<strong>{{start_and_end( h.differences.before.start, 20)}} </strong>&nbsp para &nbsp<strong>{{start_and_end( h.differences.after.start, 20)}}</strong>
              </v-subheader> 
            </div>
            <div class="col-12 px-4 pt-0 pb-4" v-if="h.notes !== ''">
              <v-subheader style="max-height: 24px" class="pl-0"><strong>Justificativa</strong></v-subheader>
              <v-subheader class="pl-0" style="display: inline; word-wrap: break-word; text-align: justify;">{{ h.notes }}</v-subheader> 
            </div>
          </div>  
        </v-card-text>
        <v-card-text class="h-title py-8 h-footer">
          <strong>Você visualizou todo histórico de alteração desta natureza</strong> 
        </v-card-text>
      </div>
      <v-card-actions class="pa-4">  
        <v-spacer />
        <v-btn class="btn-ag-primary" @click="show = false" id="btnClose"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script> 
export default {
  name: 'ModalIncidenceCPRPHistoric',
  props: ['history'],
  created() {
    this.init();
  },
  data() {
    return {
      show: false, 
    };
  },
  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close); 
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    start_and_end(str, lengthStr) {
      if (str != undefined) {
        if (str.length > lengthStr) {
          return str.substr(0, lengthStr) + '...';
        }
        return str;
      }
    }, 
  }
}
</script>
<style>
.bg-sub-title {
  background-color: #f9fafb;
}

.content-empty {
  background-color: #e7e7fa;
  color: #2a1c4f;
}
#cardScroll2 {
  flex-grow: 1;
  overflow: auto;
}

.content-empty {
  background-color: #fff;
}
.h-title {
  background-color: #e7e7fa;
}
.h-row {
  background-color: #fff;
}
.h-line {
  color: rgba(0, 0, 0, 0.6);
}
.h-footer {
  text-align: center;
  color: #2a1c4f !important;
}
hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.titleStyle {
  font-size: 20px;
  font-weight: 700 !important;
  padding: 0 !important;
  justify-content: space-between;

  line-height: 30px;
  align-content: center;
}
.titleStyle div:nth-child(2) {
  margin-left: 33px;
}

.v-dialog {
  border: 0px solid var(--v-secondary-lighten5) !important;
}
</style>